<template>
  <TopNav></TopNav>
  <h1>Ehliyet Sınavı Puanı Hesaplama</h1>
  <fieldset>
    <legend>Ehliyet Sınavı Puanı Hesaplama Aracı</legend>
    <label for="i">Toplam Doğru Sayısı: </label><input type="number" v-model.number="dogru" step="1" min="0" max="50" id="i" autofocus>
    <br>
    Ehliyet Sınavı Puanı: {{ puan() }}
    <br>
    Ehliyet Sınavı Sonucu: <span v-bind:class="classObject()">{{ sonuc() }}</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "EhliyetSinavi",
  data() {
    return {
      dogru: 0
    }
  },
  components: {
    TopNav
  },
  methods: {
    puan: function () {
      return this.dogru * 2;
    },
    sonuc: function () {
      if (this.dogru >= 35) {
        return "Ehliyet sınavını başarıyla geçtiniz.";
      } else {
        var kalan = 35 - this.dogru;
        return "Maalesef ehliyet sınavından " + kalan + " soruyla kaldınız.";
      }
    },
    classObject: function () {
      if (this.dogru >= 35) {
        return {
          "olumlu": true
        }
      } else {
        return {
          "olumsuz": true
        }
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 205px;
}

.olumlu {
  color: #008A00;
}

.olumsuz {
  color: #EB0000;
}

footer section a {
  margin: 20px;
}
</style>
